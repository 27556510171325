<template>
  <div id="app">
    <header-view/>
    <body-view/>
    <footer-view/>
  </div>
</template>

<script>

import HeaderView from './layouts/header/Header.vue';
import BodyView from './view/Body.vue';
import FooterView from './layouts/Footer.vue';

export default {
  name: 'App',
  components: {
    HeaderView,
    BodyView,
    FooterView
  }
}
</script>

<style lang="scss">

  #app {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    --el-bg-color-overlay: none;
    --el-menu-hover-bg-color: none;
  }

  html {
    margin: 0px !important;
  }

  body {
    margin: 0px !important;
    background-color: $default-primary-color;
  }

  @media screen and (max-width: 1024px) {

    #app {
      font-size: 8px;
    }

    .pagination-select {
      width: 35%;
      margin-left: 5px;
    }

    button {
      background-color: $default-button-color;
      color: white;
      border: 0px;
      border-radius: 5px;
      padding: 5px 5px;
      cursor: pointer;
      box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    }

    button:disabled {
      background-color: $disabled-button-color;
      color: white;
      border: 0px;
      border-radius: 5px;
      padding: 5px 5px;
      cursor: not-allowed;
      box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    }

    button:hover:enabled {
      background-color: var(--el-color-primary);
    }

    .el-button {
      padding: 5px 5px !important;
    }

    input {
      background-color: $default-input-background-color;
      color: $default-input-text-color;
      border: 0px;
      border-radius: 5px;
      padding: 7px 12px;
      text-align: center;
    }

    input[type="radio"] {
      cursor: pointer;
    }

    input::placeholder {
      color: white;
      font-style: italic;
    }

    .search-input {
      width: 20%;
      --color: var(--el-input-text-color,var(--el-text-color-regular));
    }

    label {
      margin-left: 5px;
      margin-top: 5px;
    }

    h1 {
      color: $default-primary-title-text-color;
    }

    h2 {
      color: $default-primary-title-text-color;
    }

    h3 {
      margin: 20px 0 5px;
      color: $default-primary-title-text-color;
      display: flex;
    }

    span {
      color: $default-primary-text-color;
      font-size: 10px;
    }

    label {
      color: $default-primary-text-color;
      font-size: 10px;
    }

    ul {
      list-style-type: none;
      padding: 0;
    }

    li {
      display: inline-block;
      margin: 0 10px;
    }

    table {
      width: 100%;
      box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
      overflow: hidden;
      border-collapse: separate;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      border-radius: 25px;
    }

    table th {
      padding-top: 25px;
      padding-bottom: 25px;
      background-color: $default-total-row-color;
      text-transform: uppercase;
    }

    table tr {
      background-color: $default-secondary-color;
    }

    table tr:nth-child(even){
      background-color: $default-layout-color;
    }

    table  td, table th {
      padding: 8px;
      text-align: center;
      color: $default-primary-text-color;
    }

    .el-collapse {
      --el-collapse-border-color: 0px !important;
      --el-collapse-header-height: 48px;
      --el-collapse-header-bg-color: $default-secondary-color !important;
      --el-collapse-header-text-color: $default-primary-text-color !important;
      --el-collapse-header-font-size: 9px;
      --el-collapse-content-bg-color: $default-secondary-color !important;
      --el-collapse-content-font-size: 9px;
      --el-collapse-content-text-color: $default-primary-text-color !important;
      padding-right: 15px;
      padding-left: 15px;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      color: $default-primary-text-color !important;
      background-color: $default-secondary-color !important;
      box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    }

    .el-collapse-item__header {
      color: $default-primary-text-color !important;
      font-size: 9px !important;
      font-weight: bold !important;
      background-color: $default-secondary-color !important;
      margin-bottom: 3px !important;
    }

    .el-collapse-item__content {
      color: $default-primary-text-color !important;
      background-color: $default-secondary-color !important;
    }

    .el-collapse-item__content table tr:nth-child(even){
      background-color: $default-layout-color !important;
    }

    .buy-order-type-class {
      color: #008000;
      font-weight: bold;
    }

    .sell-order-type-class {
      color: orange;
      font-weight: bold;
    }

    .cei-origin-tag-class {
      color: red;
      font-weight: bold;
    }

    .local-origin-tag-class {
      color: #008000;
      font-weight: bold;
    }

    .buy-tag-order-type-class {
      color: #008000;
      font-weight: bold;
    }

    .sell-tag-order-type-class {
      color: orange;
      font-weight: bold;
    }

    .executed-tag-order-status-class {
      color: #008000;
      font-weight: bold;
    }

    .pending-tag-order-status-class {
      color: rgb(124, 124, 14);
      font-weight: bold;
    }

    .not-executed-tag-order-status-class {
      color: orange;
      font-weight: bold;
    }

    .cancelled-tag-order-status-class {
      color: rgb(61, 13, 13);
      font-weight: bold;
    }

    .executed-order-status-class {
      color: #008000;
      font-weight: bold;
    }

    .pending-order-status-class {
      color: rgb(124, 124, 14);
      font-weight: bold;
    }

    .not-executed-order-status-class {
      color: orange;
      font-weight: bold;
    }

    .cancelled-order-status-class {
      color: rgb(61, 13, 13);
      font-weight: bold;
    }

    .acao-company-type-class {
      color: #fb2a36;
      font-weight: bold;
      width: 10%;
    }

    .fii-company-type-class {
      color: #207cb4;
      font-weight: bold;
      width: 10%;
    }

    .stock-company-type-class {
      color: #8d1920;
      font-weight: bold;
      width: 10%;
    }

    .bdr-company-type-class {
      color: #03a94e;
      font-weight: bold;
      width: 10%;
    }

    .fiagro-company-type-class {
      color: #07702E;
      font-weight: bold;
      width: 10%;
    }

    .etf-company-type-class {
      color: #006A8A;
      font-weight: bold;
      width: 10%;
    }

    .acao-tag-company-type-class {
      color: #fb2a36;
      font-weight: bold;
      cursor: default;
    }

    .fii-tag-company-type-class {
      color: #207cb4;
      font-weight: bold;
      cursor: default;
    }

    .stock-tag-company-type-class {
      color: #8d1920;
      font-weight: bold;
      cursor: default;
    }

    .bdr-tag-company-type-class {
      color: #03a94e;
      font-weight: bold;
      cursor: default;
    }

    .fiagro-tag-company-type-class {
      color: #07702E;
      font-weight: bold;
      cursor: default;
    }

    .etf-tag-company-type-class {
      color: #006A8A;
      font-weight: bold;
      cursor: default;
    }

    .default-tag-company-type-class {
      color: $default-primary-title-text-color !important;
      font-weight: bold;
      cursor: default;
    }

    .active-company-status-class {
      color: #008000;
      font-weight: bold;
      cursor: default;
    }

    .inactive-company-status-class {
      color: rgb(61, 13, 13);
      font-weight: bold;
      cursor: default;
    }

    .el-menu-vertical-demo {
      border: 0px !important;
    }

    .pagination {
      display: flex;
      flex-direction: column;
      margin: 10px 0px 15px;
      text-align: center;
      float: left;
    }

    .actions {
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: center;
    }

    .action {
      cursor: pointer;
      margin-top: 5px;
      margin-left: 5px;
    }

    .number-register {
      display: flex;
      flex-direction: row;
      margin: 15px 0px 15px;
      text-align: center;
      float: right;
      color: $default-primary-text-color;
    }

    .el-input {
      height: 35px;
    }

    .el-input.search-input {
      height: 30px;
      width: 40% !important;
    }

    .el-pagination {
      --el-pagination-bg-color: $default-primary-color !important;
      --el-pagination-button-bg-color: $default-primary-color !important;
      --el-pagination-hover-color: var(--el-color-primary);
      color: $default-primary-text-color !important;  
      padding: 0px;
      padding-top: 10px;
    }

    .el-pagination button {
      color: $default-primary-text-color !important;
      background-color: $default-primary-color !important;
    }

    .el-pagination button:disabled {
      color: $default-primary-text-color !important;
      background-color: $default-primary-color !important;
    }

    .el-button.is-circle {
      border-radius: 50%;
      padding: 8px;
      padding-left: 4px;
      width: 20px;
      height: 20px;
    }

    .el-icon {
      cursor: pointer;
    }

    .filter-collapse {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .el-date-range-picker .el-picker-panel__body {
      width: 100% !important;
      display: flex;
      flex-direction: column;
    }

    .el-date-editor.el-input, .el-date-editor.el-input__wrapper {
      width: 100% !important;
      height: var(--el-input-height,var(--el-component-size));
    }

    .pointer {
      cursor: pointer;
    }

    .el-select-dropdown__item span {
      color: var(--el-input-text-color,var(--el-text-color-regular)) !important;
    }

    .el-tag__content {
      --el-tag-hover-color: $default-tag-text-color !important;
      --el-tag-text-color: $default-tag-text-color !important;
      color: $default-tag-text-color !important;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .el-select-dropdown__list {
      list-style: none;
      padding: 6px 0;
      margin: 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    .el-date-table__row {
      background-color: $default-primary-color;
    }

    .el-date-table__row:nth-child(even){
      background-color: $default-secondary-color;
    }

    .el-date-range-picker {
      --el-datepicker-text-color: var(--el-text-color-regular);
      --el-datepicker-off-text-color: var(--el-text-color-placeholder);
      --el-datepicker-header-text-color: var(--el-text-color-regular);
      --el-datepicker-icon-color: var(--el-text-color-primary);
      --el-datepicker-border-color: var(--el-disabled-border-color);
      --el-datepicker-inner-border-color: var(--el-border-color-light);
      --el-datepicker-inrange-bg-color: rgb(231, 136, 136) !important;
      --el-datepicker-inrange-hover-bg-color: rgb(204, 170, 170) !important;
      --el-datepicker-active-color: var(--el-color-primary);
      --el-datepicker-hover-text-color: var(--el-color-primary);
    }

    .info-icon {
      margin-left: 5px;
    }

    .price-input {
      padding: 12px 0px;
      text-align: center;
    }

    .ordered-header {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
    }

    .filter-class {
      color: $default-primary-title-text-color;
    }

    .collapse-border {
      border-bottom: 1px solid $default-primary-title-text-color;
    }
    
    .default-dialog {
      color: black !important;
    }

    .el-input-number .amount-input {
      color: black !important;
    }

    .above-limit {
      color: #008000 !important;
    }

    .above-limit span {
      color: #008000 !important;
    }

    .under-limit {
      color: #ff070f !important;
      font-weight: bold;
    }

    .under-limit span {
      color: #ff070f !important;
    }

    .table-info {
      margin-top: 10px;
      margin-left: 5px;
    }

    .total-row {
      background-color: $default-total-row-color;
      color: white;
      font-weight: bold;
    }

    .el-menu--popup {
      padding: 0px !important;
      min-width: 100px !important;
      border: 0px;
    }

    .el-popper {
      --el-popper-border-radius : var(--el-popover-border-radius, 0px);
      border: 0px !important;
    }

    .el-menu--horizontal {
      border-bottom: 0px !important;
      border-right: none !important;
    }

    .clear-button {
      color: $default-primary-title-text-color;
      background-color: white;
      font-size: 10px;
    }

    .search-button {
      margin-left: 5px;
      font-size: 10px;
    }

    .tags {
      margin: 10px;
      justify-content: flex-start;
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 30%;
      text-align: left;
      font-weight: bold;
      text-transform: uppercase;
    }
    
    .tag {
      margin-left: 5px;
      margin-top: 5px;
      height: 20px;
    }

    .table-tag .el-tag__content {
      color: white !important;
    }

    .table-tag {
      height: 35px;
      width: 85%;
      box-shadow:  inset 0px 0px 7px 6px $default-primary-tag-bg-color;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 12px !important;
      font-weight: bold;
      background-color: inherit !important;
    }

    tr td {
      text-align: center;
      text-align: -webkit-center;
    }
    
  }

  @media screen and (min-width: 1024px) and (max-width: 1920px) {

    .pagination-select {
      width: 25%;
      margin-left: 5px;
    }

    button {
      background-color: $default-button-color;
      color: white;
      border: 0px;
      border-radius: 5px;
      padding: 10px 15px;
      cursor: pointer;
      box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    }

    button:disabled {
      background-color: $disabled-button-color;
      color: white;
      border: 0px;
      border-radius: 5px;
      padding: 10px 15px;
      cursor: not-allowed;
      box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    }

    button:hover:enabled {
      background-color: var(--el-color-primary);
    }

    input {
      background-color: $default-input-background-color;
      color: $default-input-text-color;
      border: 0px;
      border-radius: 5px;
      padding: 7px 12px;
      text-align: center;
    }

    input[type="radio"] {
      cursor: pointer;
    }

    input::placeholder {
      color: white;
      font-style: italic;
    }

    label {
      margin-left: 5px;
      margin-top: 5px;
    }

    h1 {
      color: $default-primary-title-text-color;
    }

    h2 {
      color: $default-primary-title-text-color;
    }

    h3 {
      margin: 20px 0 5px;
      color: $default-primary-title-text-color;
      display: flex;
    }

    span {
      color: $default-primary-text-color;
    }

    label {
      color: $default-primary-text-color;
    }

    ul {
      list-style-type: none;
      padding: 0;
    }

    li {
      display: inline-block;
      margin: 0 10px;
    }

    table {
      width: 100%;
      box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
      overflow: hidden;
      border-collapse: separate;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      border-radius: 25px;
    }

    table th {
      padding-top: 25px;
      padding-bottom: 25px;
      background-color: $default-total-row-color;
      text-transform: uppercase;
    }

    table tr {
      background-color: $default-secondary-color;
    }

    table tr:nth-child(even) {
      background-color: $default-layout-color;
    }

    table  td, table th {
      padding: 8px;
      text-align: center;
      color: $default-primary-text-color;
    }

    .el-collapse {
      --el-collapse-border-color: 0px !important;
      --el-collapse-header-height: 48px;
      --el-collapse-header-bg-color: $default-secondary-color !important;
      --el-collapse-header-text-color: $default-primary-text-color !important;
      --el-collapse-header-font-size: 13px;
      --el-collapse-content-bg-color: $default-secondary-color !important;
      --el-collapse-content-font-size: 13px;
      --el-collapse-content-text-color: $default-primary-text-color !important;
      padding-right: 15px;
      padding-left: 15px;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      color: $default-primary-text-color !important;
      background-color: $default-secondary-color !important;
      box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    }

    .el-collapse-item__header {
      color: $default-primary-text-color !important;
      font-size: 16px !important;
      font-weight: bold !important;
      background-color: $default-secondary-color !important;
      margin-bottom: 3px !important;
    }

    .el-collapse-item__content {
      padding: 25px !important;
      color: $default-primary-text-color !important;
      background-color: $default-secondary-color !important;
    }

    .el-collapse-item__content table tr:nth-child(even){
      background-color: $default-layout-color !important;
    }

    .buy-order-type-class {
      color: #008000;
      font-weight: bold;
    }

    .sell-order-type-class {
      color: orange;
      font-weight: bold;
    }

    .cei-origin-tag-class {
      color: red;
      font-weight: bold;
    }

    .local-origin-tag-class {
      color: #008000;
      font-weight: bold;
    }

    .buy-tag-order-type-class {
      color: #008000;
      font-weight: bold;
    }

    .sell-tag-order-type-class {
      color: orange;
      font-weight: bold;
    }

    .executed-tag-order-status-class {
      color: #008000;
      font-weight: bold;
    }

    .pending-tag-order-status-class {
      color: rgb(124, 124, 14);
      font-weight: bold;
    }

    .not-executed-tag-order-status-class {
      color: orange;
      font-weight: bold;
    }

    .cancelled-tag-order-status-class {
      color: rgb(61, 13, 13);
      font-weight: bold;
    }
    
    .executed-order-status-class {
      color: #008000;
      font-weight: bold;
    }

    .pending-order-status-class {
      color: rgb(124, 124, 14);
      font-weight: bold;
    }

    .not-executed-order-status-class {
      color: orange;
      font-weight: bold;
    }

    .cancelled-order-status-class {
      color: rgb(61, 13, 13);
      font-weight: bold;
    }

    .acao-company-type-class {
      color: #fb2a36;
      font-weight: bold;
      width: 10%;
    }

    .fii-company-type-class {
      color: #207cb4;
      font-weight: bold;
      width: 10%;
    }

    .stock-company-type-class {
      color: #8d1920;
      font-weight: bold;
      width: 10%;
    }

    .bdr-company-type-class {
      color: #03a94e;
      font-weight: bold;
      width: 10%;
    }

    .fiagro-company-type-class {
      color: #07702E;
      font-weight: bold;
      width: 10%;
    }

    .etf-company-type-class {
      color: #006A8A;
      font-weight: bold;
      width: 10%;
    }

    .acao-tag-company-type-class {
      color: #fb2a36;
      font-weight: bold;
      cursor: default;
    }

    .fii-tag-company-type-class {
      color: #207cb4;
      font-weight: bold;
      cursor: default;
    }

    .stock-tag-company-type-class {
      color: #8d1920;
      font-weight: bold;
      cursor: default;
    }

    .bdr-tag-company-type-class {
      color: #03a94e;
      font-weight: bold;
      cursor: default;
    }

    .fiagro-tag-company-type-class {
      color: #07702E;
      font-weight: bold;
      cursor: default;
    }

    .etf-tag-company-type-class {
      color: #006A8A;
      font-weight: bold;
      cursor: default;
    }

    .default-tag-company-type-class {
      color: $default-primary-title-text-color !important;
      font-weight: bold;
      cursor: default;
    }

    .active-company-status-class {
      color: #008000;
      font-weight: bold;
      cursor: default;
    }

    .inactive-company-status-class {
      color: rgb(61, 13, 13);
      font-weight: bold;
      cursor: default;
    }

    .el-menu-vertical-demo {
      border: 0px !important;
    }

    .pagination {
      display: flex;
      flex-direction: row;
      margin: 10px 0px 15px;
      text-align: center;
      float: left;
    }

    .actions {
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: center;
    }

    .action {
      cursor: pointer;
      margin-top: 5px;
      margin-left: 5px;
    }

    .number-register {
      display: flex;
      flex-direction: row;
      margin: 15px 0px 15px;
      text-align: center;
      float: right;
      color: $default-primary-text-color;
    }

    .el-input {
      height: 40px;
    }

    .el-input.search-input {
      height: 35px;
      width: 12% !important;
    }

    .search-input {
      width: 12%;
      --color: var(--el-input-text-color,var(--el-text-color-regular));
    }

    .el-pagination {
      margin-left: 5px;
      --el-pagination-bg-color: $default-primary-color !important;
      --el-pagination-button-bg-color: $default-primary-color !important;
      --el-pagination-hover-color: var(--el-color-primary);
      color: $default-primary-text-color !important;
    }

    .el-pagination button {
      color: $default-primary-text-color !important;
      background-color: $default-primary-color !important;
    }

    .el-pagination button:disabled {
      color: $default-primary-text-color !important;
      background-color: $default-primary-color !important;
    }

    .el-button.is-circle {
      border-radius: 50%;
      padding: 8px;
      padding-left: 4px;
    }

    .filter-collapse {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .pointer {
      cursor: pointer;
    }

    .el-select-dropdown__item span {
      color: var(--el-input-text-color,var(--el-text-color-regular)) !important;
    }

    .el-tag__content {
      --el-tag-hover-color: $default-tag-text-color !important;
      --el-tag-text-color: $default-tag-text-color !important;
      color: $default-tag-text-color !important;
      font-weight: bold;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .el-select-dropdown__list {
      list-style: none;
      padding: 6px 0;
      margin: 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    .el-date-table__row {
      background-color: $default-primary-color;
    }

    .el-date-table__row:nth-child(even){
      background-color: $default-secondary-color;
    }

    .el-date-range-picker {
      --el-datepicker-text-color: var(--el-text-color-regular);
      --el-datepicker-off-text-color: var(--el-text-color-placeholder);
      --el-datepicker-header-text-color: var(--el-text-color-regular);
      --el-datepicker-icon-color: var(--el-text-color-primary);
      --el-datepicker-border-color: var(--el-disabled-border-color);
      --el-datepicker-inner-border-color: var(--el-border-color-light);
      --el-datepicker-inrange-bg-color: rgb(231, 136, 136) !important;
      --el-datepicker-inrange-hover-bg-color: rgb(204, 170, 170) !important;
      --el-datepicker-active-color: var(--el-color-primary);
      --el-datepicker-hover-text-color: var(--el-color-primary);
    }

    .info-icon {
      margin-left: 5px;
    }

    .price-input {
      padding: 12px 0px;
      text-align: center;
    }

    .ordered-header {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
    }

    .filter-class {
      color: $default-primary-title-text-color;
    }

    .collapse-border {
      border-bottom: 1px solid $default-primary-title-text-color;
    }

    .el-input-number .amount-input {
      color: black !important;
    }

    .above-limit {
      color: #008000 !important;
    }

    .above-limit span {
      color: #008000 !important;
    }

    .under-limit {
      color: #ff070f !important;
      font-weight: bold;
    }

    .under-limit span {
      color: #ff070f !important;
    }

    .table-info {
      margin-top: 10px;
      margin-left: 5px;
    }

    .total-row {
      background-color: $default-total-row-color;
      color: white;
      font-weight: bold;
    }

    .el-menu--popup {
      padding: 0px !important;
      min-width: 100px !important;
      border: 0px;
    }

    .el-popper {
      --el-popper-border-radius : var(--el-popover-border-radius, 0px);
      border: 0px !important;
    }

    .search-button {
      margin-left: 5px;
    }
    
    .clear-button {
      color: $default-primary-title-text-color;
      background-color: white;
    }

    .tags {
      margin: 10px;
      justify-content: flex-start;
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 60%;
      text-align: left;
      font-weight: bold;
    }
    
    .tag {
      margin-left: 10px;
      height: 35px;
    }

    .table-tag .el-tag__content {
      color: white !important;
    }

    .table-tag {
      height: 35px;
      width: 55%;
      box-shadow:  inset 0px 0px 7px 3px $default-primary-tag-bg-color;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 12px !important;
      font-weight: bold;
      background-color: inherit !important;
    }

    tr td {
      text-align: center;
      text-align: -webkit-center;
    }

    .el-menu-item * {
      vertical-align: none;
    }

    .invalid-field {
      box-shadow: 0px 0px 8px 0px rgba(255,3,3,1);
      border-radius: 5px;
    }

    .valid-field {
      box-shadow: 0px 0px 8px 0px rgba(76,255,5,1);
      border-radius: 5px;
    }

    .is-mouse-over {
      color: $default-primary-title-text-color !important;
    }
    
    .is-active {
      color: $default-primary-title-text-color !important;
    }

    span .is-active {
      color: $default-primary-title-text-color !important;
    }

  }

  .el-tabs__header {
    margin: 0px !important;
  }

  .el-picker-panel {
    background-color: $default-secondary-color !important;
  }

  .el-date-picker {
    background-color: $default-secondary-color !important;
  }

  .el-popper {
    background-color: $default-secondary-color !important;
  }

  .el-picker-panel__header {
    background-color: $default-secondary-color !important;
  }

  .el-picker-panel__body {
    background-color: $default-secondary-color !important;
  }

  .el-picker-panel__footer {
    background-color: $default-secondary-color !important;
  }

  .el-message {
    z-index: 10000 !important;
  }

</style>
