<template>

  <div class="user-planning-section">

    <h3 style="margin-top: 0px;">
      LIMITES
      <el-tooltip class="box-item" effect="dark" content="Limites planejados por categoria" placement="top">
          <el-icon class="info-icon"><InfoFilled/></el-icon>
      </el-tooltip>
    </h3>
    
    <div class="config-table">

      <table v-loading="tableLoading" :element-loading-svg="loadingSvg" element-loading-text="Carregando..." element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="transparent">

        <thead>
            <tr>
                <th> Categoria </th>
                <th> Limite mensal </th>
                <th> Proventos </th>
                <th> % </th>
                <th> Proventos </th>
                <th> Ações </th>
            </tr>
        </thead>
        <tbody v-if="userCompanyConfigList.length > 0">
            <tr v-for="companyConfig in userCompanyConfigList" :key="companyConfig">
                <td>
                  <el-tag :key="companyConfig.type" class="mx-1 table-tag" :disable-transitions="false">
                    <span :class="companyConfig.tagTypeClassName"> {{ getType(companyConfig.type) }} </span>
                    <el-tooltip v-if="companyConfig.type === CompanyType.STOCK.value" class="box-item" effect="dark" :content="getDollarValueFormatted()" placement="top">
                        <el-icon class="info-icon"><InfoFilled/></el-icon>
                    </el-tooltip>
                  </el-tag>
                </td>
                <td>
                  <currency-input v-model="companyConfig.monthLimit" :options="getPtCurrency()" @change="companyConfig.edited = true" class="price-input"/>
                </td>
                <td> {{ getCompanyConfigDividendTotal(companyConfig) }} </td>
                <td> {{ getCompanyConfigPercentage(companyConfig) }} </td>
                <td>
                  <el-tooltip class="box-item" effect="dark" content="Marque se deseja incluir os valores de proventos para a categoria no total" placement="top">
                    <el-switch v-model="companyConfig.includeDividend"/>
                  </el-tooltip>
                </td>
                <td>

                    <div class="actions">
                        <div v-if="companyConfig.edited" class="actions">
                            <el-tooltip class="box-item" effect="dark" content="Confirmar edição" placement="top">
                                <el-button type="success" icon="Check" @click="updateUserCompaniesConfig(companyConfig)" circle class="action"/>
                            </el-tooltip>
                            <el-tooltip class="box-item" effect="dark" content="Cancelar edição" placement="top">
                                <el-button type="danger" icon="Delete" circle class="action"/>
                            </el-tooltip>
                        </div>
                        <div v-if="!companyConfig.edited">
                            -
                        </div>
                    </div>
                </td>
            </tr>
            <tr class="total-row">
              <td> TOTAL </td>
              <td> {{ getFormattedValue(userCompanyConfigTotal, "pt_br") }}  </td>
              <td> {{ getFormattedValue(userCompanyConfigDividendTotal, "pt_br") }}  </td>
              <td> 100% </td>
              <td> - </td>
              <td> - </td>
            </tr>
        </tbody>

      </table>

    </div>

  </div>

</template>

<script>

  import CurrencyInput from "@/component/common/CurrencyInput";
  import { USER_INFO_CACHE_KEY } from '../../../../constants/CacheConstants';
  import { DASHBOARD_PATH } from '../../../../constants/RoutesConstants';
  import { updateUserCompaniesConfig } from '../../../../http/bff/planning-bff-service';
  import { ElMessage } from 'element-plus'
  import { getFormattedValue } from '../../../../util/MoneyUtils';
  import { DEFAULT_LOADING_SVG } from '../../../../constants/AppConstants';
  import CompanyType from '../../../../constants/CompanyType';

  export default {
    name: 'user-planning-section',
    components: { CurrencyInput },
    props: {
      tableLoading: Boolean,
      userCompaniesConfig: Object
    },
    data() {
      return {
        loadingSvg: DEFAULT_LOADING_SVG,
        CompanyType,
        getFormattedValue
      }
    },
    mounted() {
      document.title = "Planejamento - Personal Invest";
    },
    created() {
        this.setUser();
    },
    methods: {
      goToHome() {
        this.$router.push({ path: DASHBOARD_PATH });
      },
      setUser() {
        const userJson = localStorage.getItem(USER_INFO_CACHE_KEY);
        if(userJson !== undefined || userJson !== null) {
          this.user = JSON.parse(userJson);        }
      },
      updateUserCompaniesConfig(companyConfig) {
        updateUserCompaniesConfig(companyConfig).then(() => {

            ElMessage({
                message: 'Configuração atualizada com sucesso!',
                type: 'success'
            });

            this.emitter.emit('refreshSummary', {});

        }).catch(() => {
            ElMessage({
                message: 'Configuração não pode ser atualizada, tente novamente mais tarde!',
                type: 'error'
            });
        });
      },
      getType(type) {
        return CompanyType[type].description;
      },
      getPtCurrency() {
        return { currency: "BRL" };
      },
      getDollarValueFormatted() {
        return "Cotação atual " + getFormattedValue(this.userCompaniesConfig.dollarValue, "pt_br");
      },
      getCompanyConfigPercentage(companyConfig) {

        const dividendTotal = companyConfig.dividendTotal !== null && companyConfig.includeDividend === true ? companyConfig.dividendTotal : 0;
        const percentage = (((companyConfig.monthLimit + dividendTotal) * 100)/this.userCompanyConfigTotal).toFixed(2);

        return Math.round(percentage) + "%";

      },
      getCompanyConfigDividendTotal(companyConfig) {
        return companyConfig.dividendTotal !== null ? getFormattedValue(companyConfig.dividendTotal, "pt_br") : "-";
      }
    },
    computed: {
      companyTypeList() {
        return Object.keys(CompanyType);
      },
      userCompanyConfigList() {
          this.userCompaniesConfig.companyTypeConfig.forEach(companyTypeConfig => {
            companyTypeConfig.typeClassName = CompanyType[companyTypeConfig.type].className;
            companyTypeConfig.tagTypeClassName = CompanyType[companyTypeConfig.type].tagTypeClassName;
            companyTypeConfig.edited = false;
          });
          return this.userCompaniesConfig.companyTypeConfig;
      },
      userCompanyConfigTotal() {
        return this.userCompanyConfigList.reduce((partialSum, config) => {
          return partialSum + config.monthLimit;
        }, 0) + this.userCompanyConfigDividendTotal;
      },
      userCompanyConfigDividendTotal() {
        return this.userCompanyConfigList.reduce((partialSum, config) => {

          if(config.includeDividend === true) {
            return partialSum + config.dividendTotal;
          }
          
          return partialSum;

        }, 0);
      }
    }
  }

</script>

<style scoped>

  @media screen and (max-width: 1024px) {

    .price-input {
      width: 100% !important;
      font-size: 8px;
    }

    .table-tag {
      width: 80%;
    }

    .config-table {
      overflow: auto;
    }

  }

  @media screen and (min-width: 1024px) and (max-width: 1920px) {

    .table-tag {
      width: 40%;
    }

  }

  .user-planning-section {
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

</style>
